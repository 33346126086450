<template>
  <b-card>
    <b-row>
      <b-col md="6">
        <h3>Catálogo de redes</h3>
        <b-list-group class="mt-2">
          <b-list-group-item
            v-for="n in networks"
            :key="n['.key']"
            :to="{ name: 'admin-networks-organizations', params: { id: n['.key'] }}"
          >
            {{ n.name }}
          </b-list-group-item>
          <b-list-group-item>
            <b-form inline>
              <b-form-input
                v-model="newNetworkName"
                placeholder="nueva red"
              />
              <b-button
                variant="success"
                class="ml-2"
                :disabled="newNetworkName === ''"
                @click="onAddClick"
              >
                Añadir
              </b-button>
            </b-form>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem,
  BFormInput, BRow, BCol, BButton,
  BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import networks from '../../../services/networks'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm,
  },
  data() {
    return {
      networks: [],
      newNetworkName: '',
      saving: false,
    }
  },
  firebase: {
    networks: networks.bindAll(),
  },
  methods: {
    onAddClick() {
      this.saving = true
      try {
        networks.create({ name: this.newNetworkName })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al guardar',
            text: e.message,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>
